import { Text } from "@clipboard-health/ui-react";
import { type TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
import { type ReactElement } from "react";

interface NfcLocationsDescriptionProps {
  nfcTagRequests: NfcTagRequest[];
  facilityName: string;
  textVariant?: TextVariant;
  listTextVariant?: TextVariant;
}

export function NfcLocationsDescription({
  nfcTagRequests,
  facilityName,
  textVariant,
  listTextVariant,
}: NfcLocationsDescriptionProps): ReactElement {
  const tagLocations = nfcTagRequests
    .map((tagRequest) => tagRequest.tagLocation)
    .filter<string>(
      (tagLocation): tagLocation is string => isDefined(tagLocation) && tagLocation !== ""
    );

  const textVariantType = textVariant ?? "body2";
  const listVariantType = listTextVariant ?? "body2";

  return (
    <>
      {tagLocations.length === 0 && (
        <Text variant={textVariantType}>
          If you need help locating an NFC Time Clock poster at {facilityName}, please ask a
          facility admin.
        </Text>
      )}
      {tagLocations.length === 1 && (
        <Text variant={textVariantType}>
          {facilityName} has an NFC Time Clock poster located at the following location:{" "}
          <em>{tagLocations[0]}</em>.
        </Text>
      )}
      {tagLocations.length > 1 && (
        <Box>
          <Text variant={textVariantType}>
            {facilityName} has NFC Time Clock posters located at the following locations:{" "}
          </Text>
          <Stack mt={1}>
            {tagLocations.map((tagLocation, index) => (
              <Text key={tagLocation} variant={listVariantType} pl={1}>
                {index + 1}. <em>{tagLocation}</em>
              </Text>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}
